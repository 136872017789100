import request from '../libs/axios'

export function getExperiences(params) {
  return request({
    url: '/api/experience/index',
    method: 'get',
    params,
  })
}

export function storeExperience(data) {
  return request({
    url: '/api/experience/index',
    method: 'post',
    data,
  });
}

export function showExperience(data) {
  return request({
    url: `/api/experience/index/${data.id}`,
    method: 'get',
    data,
  });
}

export function updateExperience(data) {
  return request({
    url:`/api/experience/index/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function deleteExperience(data) {
  return request({
    url: `/api/experience/index/${data.id}`,
    method: 'delete',
  });
}
