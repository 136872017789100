<template>
  <section class="category-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <div class="w-100">
        <b-form-group :label="$t('message.ExperienceUz')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.ExperienceUz')"
              rules="required"
          >
            <b-input v-model="form.name_uz" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.ExperienceRu')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.ExperienceRu')"
              rules="required"
          >
            <b-input v-model="form.name_ru" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.ExperienceEn')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.ExperienceEn')"
              rules="required"
          >
            <b-input v-model="form.name_en" required></b-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('message.Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { storeExperience, updateExperience, showExperience } from '@/api/experience'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';

function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    name_uz: get('name_uz', null),
    name_ru: get('name_ru', null),
    name_en: get('name_en', null),
  }
}

const actions = {
  add:storeExperience,
  update: updateExperience,
  show: showExperience,
}

export default {
  name: 'Form',
  components:{
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
    }
  },
  methods: {
    reformatData() {
      let form = this.prepareFormData(this.form);
      return form;
    },
    setForm(data) {
      this.form = initForm(data);
    },
  }
}
</script>

<style scoped>

</style>
